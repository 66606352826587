import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { cn, IconButton } from '@kiiwi/ui';
import { headingStyles } from '@kiiwi/ui/dist/Heading';
import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';

/**
 * TODO
 * update kiiwi with a "mobile" dialog attribute
 * OR create a custom Dialog based on existing.
 * Wait till Dialog will not be experimental anymore
 *  */

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      [
        'fixed inset-0 z-50 ',
        'fixed inset-0 z-50 bg-black/20',
        'data-[state=open]:animate-in data-[state=open]:fade-in-0',
        'data-[state=closed]:animate-out data-[state=closed]:fade-out-0',
      ],
      className,
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

type DialogContentProps = React.ComponentPropsWithoutRef<
  typeof DialogPrimitive.Content
> & {
  overlayClassName?: string;
};

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps
>(({ className, overlayClassName, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay className={overlayClassName} />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        [
          'fixed bottom-0 left-0 top-[unset] flex max-h-[80%] w-[100%] max-w-[100%] transform-none flex-col overflow-auto sm:bottom-[unset] sm:left-[50%] sm:top-[50%] sm:w-[unset] sm:min-w-[580px] sm:translate-x-[-50%] sm:translate-y-[-50%]',
          ' z-50 rounded-lg border border-neutral bg-surface-white shadow-1 duration-200',
          'data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95 data-[state=open]:slide-in-from-bottom-[48%] sm:data-[state=open]:slide-in-from-left-1/2 sm:data-[state=open]:slide-in-from-top-[48%]',
          'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[state=closed]:slide-out-to-bottom-[48%] sm:data-[state=closed]:slide-out-to-left-1/2 sm:data-[state=closed]:slide-out-to-top-[48%]',
        ],
        className,
      )}
      {...props}
    >
      {children}
      <DialogPrimitive.Close asChild>
        <IconButton size="sm" className="absolute right-4 top-3">
          <FontAwesomeIcon name="xmark:regular" className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </IconButton>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col border-b border-neutral py-3.5 pl-4 pr-12',
      className,
    )}
    {...props}
  />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('flex flex-row justify-end gap-2 p-4', className)}
    {...props}
  />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => {
  const heading = headingStyles({ variant: 'subtitle-1' });
  return (
    <DialogPrimitive.Title
      ref={ref}
      className={cn(heading, 'font-semibold text-neutral-primary', className)}
      {...props}
    />
  );
});
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogBody = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('overflow-auto p-4', className)} {...props} />
));
DialogBody.displayName = 'DialogBody';

export {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
};
