import { toast } from '@kiiwi/ui';
import { usePost as usePostEmailVerification } from '@maiia/model/generated/model/api-patient/hooks/patAccountEmailVerification';
import { useTranslation } from '@/src/i18n';

type Props =
  | {
      onSuccess?: () => void;
    }
  | undefined;

const useSendEmailVerification = ({ onSuccess }: Props = {}) => {
  const { t } = useTranslation();

  const mutation = usePostEmailVerification({
    onSuccess: () => {
      toast({
        variant: 'success',
        description: t('email-verification-toast-new-link-sent'),
        closable: true,
      });
      onSuccess?.();
    },
    onError: () => {
      toast({
        variant: 'error',
        description: t('email-verification-toast-new-link-not-sent'),
        closable: true,
      });
    },
  });

  return mutation;
};

export default useSendEmailVerification;
