import React from 'react';
import { Button, DialogClose, DialogHeader, Typography } from '@kiiwi/ui';
import { create } from 'zustand';
import { useGet as useGetEmailVerificationStatus } from '@maiia/model/generated/model/api-patient/hooks/patAccountEmailVerificationStatus';
import { useTranslation } from '@/src/i18n';
import {
  DialogFooter,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogBody,
} from '@/components/ui/not-existing-kiiwi-components/Dialog';
import useSendEmailVerification from '@/src/hooks/email-verification/useSendEmailVerification';

interface EmailVerificationDialogState {
  isEnabled: boolean;
  enable: () => void;
  disable: () => void;
}

export const useEmailVerificationDialogState = create<
  EmailVerificationDialogState
>()(set => ({
  isEnabled: false,
  enable: () => set(() => ({ isEnabled: true })),
  disable: () => set(() => ({ isEnabled: false })),
}));

const EmailVerificationDialog = () => {
  const { t } = useTranslation();
  const { isEnabled } = useEmailVerificationDialogState();
  const emailVerificationStatus = useGetEmailVerificationStatus();
  const [isOpen, setIsOpen] = React.useState(false);

  const {
    mutate: mutateSendEmailVerification,
    isLoading: isLoadingSendEmailVerification,
    isSuccess: isSuccessSendEmailVerification,
  } = useSendEmailVerification({
    onSuccess: () => {
      setIsOpen(false);
    },
  });

  const isSubmitButtonDisabled =
    isLoadingSendEmailVerification || isSuccessSendEmailVerification;
  const isCancelButtonDisabled = isLoadingSendEmailVerification;

  const handleOnOpenChange = (newOpenValue: boolean) => {
    setIsOpen(newOpenValue);
  };

  const handleOnTriggerEmailVerification = () => {
    mutateSendEmailVerification({});
  };

  React.useEffect(() => {
    if (
      isEnabled &&
      emailVerificationStatus.data?.status === 'VERIFICATION_REQUIRED'
    ) {
      setIsOpen(true);
    }
  }, [isEnabled, emailVerificationStatus.data]);

  return (
    <Dialog open={isOpen} onOpenChange={handleOnOpenChange}>
      <DialogContent
        className="z-[1301] max-w-[580px]"
        overlayClassName="z-[1300]"
      >
        <DialogHeader>
          <DialogTitle>{t('email-verification-dialog-title')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <img
            src="/static/images/email-verification.svg"
            alt=""
            className="m-auto"
          />
          <Typography className="mt-6 font-semibold text-neutral-primary">
            {t('email-verification-dialog-text')}
          </Typography>
        </DialogBody>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="ghost" disabled={isCancelButtonDisabled}>
              {t('email-verification-dialog-button-later')}
            </Button>
          </DialogClose>
          <Button
            variant="primary"
            onClick={handleOnTriggerEmailVerification}
            disabled={isSubmitButtonDisabled}
          >
            {t('email-verification-dialog-button-submit')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EmailVerificationDialog;
