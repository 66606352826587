/* eslint-disable */
/* tslint:disable */
/*
 * -------------------------------------------------------------------
 * ## THIS FILE WAS GENERATED via our custom react-query-generator  ##
 * ##                                                               ##
 * ## DO NOT MODIFY                                                 ##
 * -------------------------------------------------------------------
 *
 * Tanstack Query Version : `4`
 *
 * Implementation for `patAccountEmailVerification` hook
 * Endpoint: `/pat/account/email-verification`
 */

import { useQueryClient, useMutation } from "@tanstack/react-query";

import omitBy from "lodash/omitBy";
import pick from "lodash/pick";

import { useApiClient } from "../../../core/react-query";

export function usePost(mutationOptions) {
	const { getApiClient } = useApiClient();
	// todo: add invalidateQuery mechanism
	return useMutation({
		...mutationOptions,
		mutationFn: (variables) => {
			return getApiClient({ apiName: "api-patient" })
				.post(`/pat/account/email-verification`, variables.$payload, {})
				.then(({ data }) => data);
		},
	});
}
